<script>
import { useNotificationStore } from '~/stores/account/notifications.store';
export default {
  name: "MobileNotificationMarkAllRead",
  data() {
    return {
      notificationStore: useNotificationStore(),
      isOpen: false,
    }
  },
  methods: {
    async markAllRead() {
      await this.notificationStore.markAllRead();
      window.location = window.location
    }
  }
}
</script>
<template>
  <div class="text-xs text-white ms-auto block" @click="() => isOpen = true">
    <div class=" block relative">
      <i class="toggle-btn fa-solid fa-ellipsis-vertical fa-2xl "></i>
    </div>
  </div>

  <w-slideover v-model="isOpen">

    <div class="flex items-center gap-2 p-4 cursor-pointer">
      <div class="h-10 w-10 leading-10 text-center bg-gray-300 rounded-full left-50 -translate-x-50">
        <i class="fa-solid fa-envelope text-gray-600"></i>
      </div>
      <div @click="markAllRead">{{ $t('client.mark_all_as_read') }}</div>
    </div>
  </w-slideover>
</template>